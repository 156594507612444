<template>
    <div v-if="!isLoading">
        <div class="guest-view"></div>
        <div class="container login-wrapper">
            <div class="logo-container">
                <img :src="require('../assets/app_icon/Logo_BM_pion.png')"/>
            </div>
            <div class="login-links-container">
                <div class="w-100">
                    <!-- <button class="btn-white-full mb-3">Facebook</button> -->
                </div>
                <router-link to="/logowanie/email" class="w-100">
                    <button class="btn-white-border">Email</button>
                </router-link>
                <button v-if="platform !== 'ios'" class="btn-white-border mt-2" @click="loginWithFacebook()">Facebook</button>
            </div>
            <div class="register-container">
                <!-- <button class="btn-black-full mb-3">{{isIOS ? 'Sign in with Apple' : 'Sign in with Google'}}</button> -->
                <div>Pierwszy raz na Bookme?
                    <router-link to="/rejestracja/regulamin">
                        <a href="#">Załóż konto</a>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-gray" v-else>
        <Loader/>
    </div>
</template>
<script>
import Loader from '../components/Loader'

export default {
  name: 'Login',
  components: {
    Loader
  },
  data () {
    return {
      isIOS: true,
      isLoading: false,
      platform: window.platform
    }
  },
  methods: {
    loginWithFacebook () {
      window.open('https://www.facebook.com/v5.0/dialog/oauth?client_id=1651672578279249&state=c390d21215195b2e18a7e50c8f8d5e48&response_type=code&redirect_uri=https%3A%2F%2F' + this.appUrl + '%2Ffacebook-return&scope=email')
    }
  },
  created () {
    if (this.getToken()) {
      this.$router.replace('/')
    }
  },
  mounted () {
    const code = this.$route.query.code
    if (code) {
      this.isLoading = true
      this.$https('/fbauth?code=' + code + '&state=c390d21215195b2e18a7e50c8f8d5e48', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      }).then(res => {
        const token = res.data.response.token
        const signup = res.data.response.signup
        this.isLoading = false
        if (!token) {
          this.errorMessage = 'Niepoprawny login lub hasło'
          return
        }
        this.saveToken(token)
        if (signup) {
          this.$router.push('/konto/stworz-profil')
        } else {
          this.$router.push('/')
        }
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
